<template>
  <div
    class="section !pb-0"
    :class="[...sectionBackgroundClass(background)]"
  >
    <div class="container">
      <h2
        v-if="titleEnabled"
        class="mb-4"
      >
        {{ title }}
      </h2>
    </div>

    <UiMapsMap
      class-map="min-h-[700px]"
      :center-bounds="true"
      :markers="markers"
      :min-zoom="7"
      :zoom="8"
    >
      <template #default="{marker}">
        <UiMapsMarker
          :latitude="marker.address.latitude"
          :longitude="marker.address.longitude"
          type="community"
          @click="navigateTo(marker.domain, {external: true})"
        />
      </template>
    </UiMapsMap>
  </div>
</template>

<script setup lang="ts">
import type {SectionProps} from '~/types/section';

defineProps<SectionProps>();

const {tenants} = useTenants({
  onlyCongregations: true,
});

const markers = computed(() => tenants.value
  .filter(tenant => tenant.address?.latitude && tenant.address?.longitude)
  .map(tenant => ({
    ...tenant,
    domain: `https://${tenant.domain}`,
    latitude: tenant.address!.latitude!,
    longitude: tenant.address!.longitude!,
  })),
);
</script>
